<template>
  <div class="login_head isShadow">
    <div class="top">
      <div class="top_left">
        <el-dropdown size="small" @command="commandClick">
          <span class="el-dropdown-link">
            {{ lang == "zh" ? "中文" : "Español"
            }}
            <i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh">中文</el-dropdown-item>
            <el-dropdown-item command="sp">Español</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- <div class="menu" @click="typeClick">
				<img src="../assets/home_icons/menu.png" />
      </div>-->
      <div class="top_right">
        <!-- <div class="item" @click="itemClick(1)" >{{ $t('other.homePage') }}</div>
				<div class="item" @click="itemClick(2)" >{{ $t('set.aboutUs') }}</div>
				<div class="item" @click="itemClick(3)" >{{ $t('contactUs') }}</div>
        <div class="item" @click="itemClick(5)" >{{ $t('order.applyAd') }}</div>-->

        <!-- 申请广告位 -->
        <!-- <div
          class="item"
          @click="goAd()"
          v-if="$store.state.userinfo"
          style="display: flex"
        >
          <div class="img">
            <img
              src="@/assets/new_login/ad.png"
              style="width: 18px; height: 18px; margin-right: 7px"
            />
          </div>
          <div>
            {{ $t("order.applyAd") }}
          </div>
        </div>-->
          <!-- 联系我们 -->
          <div class="item" @click="itemClick(6)" v-if="$store.state.userinfo">
          <div class="img">
            <!-- <img
              src="@/assets/new_login/rencai.png"
              style="width: 18px; height: 18px; margin-right: 7px"
            /> -->
          </div>
          <div>{{ $t("contactUs") }}</div>
        </div>

        <!-- 人才招聘 -->
        <div class="item" @click="itemClick(4)" v-if="$store.state.userinfo">
          <div class="img">
            <img
              src="@/assets/new_login/rencai.png"
              style="width: 18px; height: 18px; margin-right: 7px"
            />
          </div>
          <div>{{ $t("talent") }}</div>
        </div>

        <div
          v-if="!$store.state.userinfo"
          @click="btnClick(1)"
          :class="['way', curWay == 1 ? 'way_select' : '']"
        >{{ $t("login.login") }}</div>
        <div
          v-if="!$store.state.userinfo"
          @click="btnClick(2)"
          :class="['way', curWay == 2 ? 'way_select' : '']"
        >{{ $t("login.regiser") }}</div>

        <!-- 消息栏 -->
        <div class="message" @click="toMessage" v-if="$store.state.userinfo">
          <div class="message_img">
            <img src="@/assets/home_icons/message.png" />
          </div>
          <div>
            {{ $t("message")
            }}
            <span v-if="unReadCount != 0">({{ unReadCount }})</span>
          </div>
        </div>

        <!-- 下载app -->
        <el-dropdown size="small">
          <span class="el-dropdown-link">
            <div class="download">
              <div class="img">
                <img src="@/assets/new_login/download.png" />
              </div>
              <div>{{ $t("download") }}</div>
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
            <div class="qrcode">
              <div>
                <img :src="photo" alt style="width: 200px; height:200px;" />
                <!-- <vue-qr :text="qrText" :size="188" :logoSrc="logoSrc"></vue-qr> -->
              </div>
              <div class="download_title">{{ $t("scanCodeToDownload") }}</div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>

        <!-- 用户栏 -->
        <el-dropdown size="small" @command="exitLogin" v-if="$store.state.userinfo">
          <span class="el-dropdown-link" @click="toPersonClick">
            <div class="person">
              <div class="img">
                <img src="@/assets/home_icons/person.png" />
              </div>
              <div>{{ $store.state.userinfo.user_nickname }}</div>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>{{ $t("set.exitLogin") }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import { product_host } from "@/common/utils/config.js";

export default {
  props: {},
  data() {
    return {
      logoSrc:
        "http://www.publichino.com/uploads/images/d1/13b60ffc609e27500ae05e1d16121a.png",
      qrText: product_host + "/shareDownloadApp/index.html", //页面地址',

      curWay: 1,
      unReadCount: "", //未读消息数量
      lang: localStorage.getItem("lang"),
      isLogin: "", //是否登录
      photo: ""
    };
  },
  components: {
    vueQr
  },

  // watch: {
  //   $route(val, from) {
  //     // 拿到目标参数 val.query.id 去请求接口
  //     console.log(val);
  //     if (this.isLogin) {
  // 	console.log(123456);
  //       this.getUnread();
  //     }
  //   },
  // },

  mounted() {
    // this.getUnread();
    this.isLogin = this.$store.state.userinfo;
    this.curWay = this.$route.query.curWay;
    this.getEeWeiMa();

    if (this.isLogin) {
      console.log(12345678);
      this.getUnread();
    }
  },
  methods: {
    // 获取二维码
    getEeWeiMa() {
      this.$http.erweima().then(res => {
        if (res.code == 1) {
          this.photo = res.data.two_dimensional_code;
          console.log(this.photo, "this.photo");
        }
      });
    },
    // typeClick() {
    // 	this.$store.commit('changeShowFilter');
    // },
    btnClick(val) {
      this.curWay = val;
      if (val == 1) {
        this.$router.push({
          path: "/accountLogin",
          query: {
            curWay: 1
          }
        });
      } else {
        this.$router.push({
          path: "/registerAccount",
          query: {
            curWay: 2
          }
        });
      }
    },
    // 去个人中心
    toPersonClick() {
      if (this.$store.state.isShowFilter == true) {
        this.$store.state.isShowFilter = false;
      }
      this.$router.push({
        // path: '/home/person/personInfor',
        path: "/person",
        query: {
          curCenterIndex: 0
        }
      });
    },
    // 获取未读消息
    getUnread() {
      this.$http.getUnread().then(res => {
        if (res.code == 1) {
          this.unReadCount = res.data.num;
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 去消息
    toMessage() {
      if (this.$store.state.isShowFilter == true) {
        this.$store.state.isShowFilter = false;
      }
      this.$router.push({
        path: "/myMessage",
        query: {
          curCenterIndex: 1
        }
      });
    },
    // 下拉框点击事件
    commandClick(e) {
      this.lang = e;
      this._i18n.locale = e;
      localStorage.setItem("lang", e);
    },
    // 退出登录
    exitLogin() {
      localStorage.removeItem("userinfo");
      this.$store.commit("edituserinfo", "");
      this.$router.push("/accountLogin");
    },

    // 申请广告位
    // goAd(){
    //   this.$router.push({
    //     path:'/applyAd'
    //   })
    // },

    // 小标题点击
    itemClick(val) {
      console.log(val);
      if (this.$store.state.isShowFilter == true) {
        this.$store.state.isShowFilter = false;
      }

      // console.log(this.$store.state.isShowFilter,'就加快速度')
      // return false;
      if (val == 4 || val == 5) {
        if (!this.$store.state.userinfo) {
          this.$message.error(this.$t("other.noLoginPleaseToLogin"));
          setTimeout(() => {
            this.$router.push({
              path: "/accountLogin"
            });
          }, 1500);
          return;
        } else {
          this.$router.push({
            path: "/talentRecruitment"
          });
        }
      }

      switch (val * 1) {
        case 1:
          this.$router.push("/");
          break;
        case 2:
          this.$router.push({
            // path: '/home/aboutUs', //曹梦玲写的
            path: "/aboutUs" //关于我们
          });
          break;
        case 3:
          this.$router.push({
            // path: '/home/contactUs',//曹梦玲写的
            path: "/contactUs" //联系我们
          });
          break;
        case 4:
          this.$router.push({
            // path: '/home/talentRecruitment', //曹梦玲写的
            path: "/talentRecruitment" //人才招聘
          });
          break;
        case 5:
          this.$router.push({
            // path: '/home/person/applyAd?curCenterIndex=7',//曹梦玲写的
            path: "/applyAd?curCenterIndex=7" //申请广告
          });
          break;
          case 6:
          this.$router.push({
            // path: '/home/person/applyAd?curCenterIndex=7',//曹梦玲写的
            path: "/aboutUs" //联系我们
          });
          break;
      }
    }
  }
};
</script>

<style scoped="" lang="less">
.login_head {
  background-color: #ffffff;
  // margin-bottom: 6px;
}

.isShadow {
  box-shadow: 0px 3px 6px rgba(194, 202, 221, 0.26);
}

.top {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 14px 40px;
  box-sizing: border-box;

  .top_left {
    &:hover {
      cursor: pointer;
    }
  }

  .top_right {
    display: flex;
    align-items: center;

    .item {
      display: flex;
      cursor: pointer;
      align-items: center;

      .img{
        display: flex;
        align-items: center;
      }
    }

    .way:hover {
      // color: #F39800;
      cursor: pointer;
    }

    .way_select {
      color: #f39800;
    }

    & > div {
      margin-right: 40px;
    }

    & > div:last-child {
      margin-right: 0;
    }

    .message {
      display: flex;
      align-items: center;
      font-size: 14px;

      &:hover {
        cursor: pointer;
      }

      .message_img {
        display: flex;
        align-items: center;

        img {
          margin-right: 5px;
          width: 18px;
          height: 18px;
        }
      }

      span {
        color: #f39800;
      }
    }

    .download {
      display: flex;
      align-items: center;
      font-size: 14px;

      &:hover {
        cursor: pointer;
      }

      .img {
        display: flex;
        align-items: center;

        img {
          margin-right: 5px;
          width: 18px;
          height: 18px;
        }
      }
    }

    .person {
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      .img {
        display: flex;
        align-items: center;

        img {
          margin-right: 5px;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

.qrcode {
  padding: 12px;

  // img {
  // 	width: 188px;
  // 	height: 188px;
  // }

  .download_title {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    font-size: 16px;
  }
}

.small_logo {
  margin-top: 42px;
  text-align: left;

  img {
    width: 162px;
    height: 44px;
  }
}

.menu {
  cursor: pointer;

  img {
    width: 32px;
    height: 26px;
  }
}
</style>
